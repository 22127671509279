<template>
  <div class="loading" v-if="display">
    <img :src="loadingImg" />
  </div>
</template>

<script>
import loadingImg from 'images/spinner.svg'

export default {
  data: function() {
    return {
      display: false,
      loadingImg: loadingImg
    }
  },
  methods: {
    show: function() {
      this.display = true;
    },
    hide: function() {
      this.display = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.loading {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999999;
  background: #00000099;
  align-items: center;
  justify-content: center;
}
</style>