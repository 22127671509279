import Vue from 'vue/dist/vue.esm'
import Vuex from 'vuex'
Vue.use(Vuex);

import createPersistedState from "vuex-persistedstate";

import global from './modules/global'

const store = new Vuex.Store({
  namespace: true,
  plugins: [createPersistedState({
    key: "pinokochan-data-store",
  })],
  modules: {
    global,
  }
});

export default store;