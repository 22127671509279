const TRIGGER_UPDATE_CART = "TRIGGER_UPDATE_CART";
const UPDATE_CART = "UPDATE_CART";
const INSERT_TO_CART = "INSERT_TO_CART";
const UPDATE_TOTAL = "UPDATE_TOTAL";
const UPDATE_COUNT = "UPDATE_COUNT";
const CLEAR_CART = "CLEAR_CART";

export default {
  namespaced: true,
  state: {
    cartInfo: 0,
    cartUpdated: false,
    cart: {
      name: "",
      phone: "",
      address: "",
      memo: "",
      total: 0,
      count: 0,
      order_items: []
    },
    cartCount: 0
  },
  actions: {
    triggerUpdateCart({ commit }, payload) {
      commit(TRIGGER_UPDATE_CART, payload);
    },
    updateCart({ commit }, payload) {
      commit(UPDATE_CART, payload);
      commit(UPDATE_TOTAL, payload);
      commit(UPDATE_COUNT, payload);
    },
    insertToCart({ commit }, payload) {
      commit(INSERT_TO_CART, payload);
      commit(UPDATE_TOTAL, payload);
      commit(UPDATE_COUNT, payload);
    },
    clearCart({ commit }, payload) {
      commit(CLEAR_CART, payload);
    }
  },
  mutations: {
    TRIGGER_UPDATE_CART(state) {
      state.cartUpdated = false;
      setTimeout(() => state.cartUpdated = true, 100);
    },
    UPDATE_CART(state, payload) {
      state.cart = payload;
    },
    INSERT_TO_CART(state, payload) {
      const cart = state.cart;
      const find = cart.order_items.find(item => item.product.id == payload.product.id);

      if (find) {
        find.quantity++;
      } else {
        cart.order_items.push(payload);
      }
    },
    CLEAR_CART(state) {
      state.cart = {
        name: "",
        phone: "",
        address: "",
        memo: "",
        total: 0,
        count: 0,
        order_items: []
      }

      state.cartCount = 0;
    },
    UPDATE_TOTAL(state) {
      const cart = state.cart;
      cart.total = 0;
      for (const item of cart.order_items) {
        cart.total += item.quantity * item.product.price;
      }
    },
    UPDATE_COUNT(state) {
      state.cartCount = state.cart.order_items.length;
      state.cart.count = state.cartCount;
    }
  },
};
