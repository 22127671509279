// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");

import "babel-polyfill";
import "whatwg-fetch";

import Vue from "vue/dist/vue.esm";
import "../styles/public/application";
import "@fortawesome/fontawesome-free";

import lodash from "lodash";
Vue.prototype._ = lodash;

import Toasted from "vue-toasted";

import filters from "../src/filters";
for (let name in filters) {
  Vue.filter(name, filters[name]);
}

import VueLazyload from "vue-lazyload";
Vue.use(VueLazyload);

const ellipsisText = (e, length) => {
  if (e.innerHTML.length > length) {
    e.innerHTML = e.innerHTML.substring(0, length) + "...";
  }
};

Vue.directive("plaintext", {
  bind: function (el, binding, vnode) {
    el.innerHTML = el.innerText;
    ellipsisText(el, binding.value);
  },
});

Vue.directive("centered-image", {
  bind: function (el, binding) {
    el.onload = function (event) {
      if (event.path?.length) {
        const img = event.path[0];
        if (img.width > img.height) {
          el.classList.add(binding.value || "landscape");
        } else {
          el.classList.remove(binding.value || "landscape");
        }
      }
    }
  }
})

import Vue2TouchEvents from "vue2-touch-events";
Vue.use(Vue2TouchEvents);

import dayjs from "dayjs";
import "dayjs/locale/vi";
dayjs.locale("vi");
Vue.prototype.$dayjs = dayjs;

require.context("../images", true);

import Loading from "../src/components/Shared/SharedLoading";

import store from "../src/store/public";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
Vue.use(VueSweetalert2);

import VueContentPlaceholders from "vue-content-placeholders";
Vue.use(VueContentPlaceholders)

// VeeValidate
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize,
} from "vee-validate";
import vi from "vee-validate/dist/locale/vi.json";
import * as rules from "vee-validate/dist/rules";
Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

localize("vi", vi);

Vue.prototype.lockScreen = () => document.body.style.overflow = "hidden";
Vue.prototype.unlockScreen = () => document.body.style.overflow = null;

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

//
document.addEventListener("DOMContentLoaded", () => {
  document.onscroll = function () {
    const header = document.querySelector("#header .header-main");

    if (header) {
      if (window.pageYOffset > header.offsetTop) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    }
  };

  const vue = new Vue({
    el: "#app",
    store: store,
    components: {
      SharedHeaderMenu: () =>
        import("../src/components/Shared/SharedHeaderMenu"),
      HomePageWelcomeSlider: () =>
        import("../src/components/Homepage/HomePageWelcomeSlider"),
      RecommendProductsList: () =>
        import("../src/components/Products/Recommend/RecommendProductsList"),
      HomePageOurServiceSection: () =>
        import("../src/components/Homepage/HomePageOurServiceSection"),
      HomePageSupportSection: () =>
        import("../src/components/Homepage/HomePageSupportSection"),
      HomePageBenefitSection: () =>
        import("../src/components/Homepage/HomePageBenefitSection"),
      SharedFooterSection: () =>
        import("../src/components/Shared/SharedFooterSection"),
      HomePageBrands: () =>
        import("../src/components/Homepage/HomePageBrands"),
      ProductModal: () =>
        import("../src/components/Products/Component/ProductModal"),
      PageIndexProducts: () =>
        import("../src/components/Products/Page/Index/PageIndexProducts"),
      PageShowProduct: () =>
        import("../src/components/Products/Page/Show/PageShowProduct"),
      OrdersIndex: () =>
        import("../src/components/Orders/Page/OrdersIndex"),
      CartIndex: () => import("../src/components/Cart/CartIndex"),
      CartCheckout: () => import("../src/components/Cart/CartCheckout"),
      CartFinish: () => import("../src/components/Cart/CartFinish"),
      UsersIndex: () => import("../src/components/Users/UsersIndex"),
      UsersPassword: () => import("../src/components/Users/Password"),
      SharedLoading: Loading,
      StaticPage: () => import("../src/components/Static"),
      ContactPage: () => import("../src/components/Contact")
    },
  });

  Vue.use(Toasted);
});
